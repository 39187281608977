import { createBrowserRouter, Outlet } from "react-router-dom";

import Landing from "./Pages/Landing";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";

const ErrorBoundaryLayout = () => (
	<ErrorBoundary fallback={<div style={{ background: "red" }}>Something went wrong</div>}>
		<Outlet />
	</ErrorBoundary>
);

export const router = createBrowserRouter([
	{
		element: <ErrorBoundaryLayout />,
		children: [
			{
				path: "/",
				Component: Landing
			}
		]
	}
]);

import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { landingSlice } from "./slices";
import { api } from "./services";

// const rootReducer = combineReducers({
// 	[landingApi.reducerPath]: landingSlice
// });

// const store = configureStore({
// 	reducer: rootReducer,
// 	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(landingApi.middleware as ThunkMiddleware)
// });

export const createStore = (options?: ConfigureStoreOptions["preloadedState"] | undefined) =>
	configureStore({
		reducer: {
			landing: landingSlice,

			[api.reducerPath]: api.reducer
		},
		middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
		...options
	});

export const store = createStore();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import { createSlice } from "@reduxjs/toolkit";

import { landingAPI } from "../../services/landing/landing.service";
interface landingState {
	templates: {}[];
}

const initialState: landingState = {
	templates: []
};

const landingSlice = createSlice({
	name: "landing",
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder.addMatcher(landingAPI.endpoints.getInvitations.matchFulfilled, (state, action) => {
			state.templates = [{ ...action.payload }];
		})
});

// export const { increment, decrement, incrementByAmount } = landingSlice.actions;
export default landingSlice.reducer;

import React from "react";

import { useGetInvitationsQuery, useCreateInvitationMutation } from "../../store/services/landing/landing.service";

const Landing = () => {
	const { data, error } = useGetInvitationsQuery();
	const [invite, { isLoading }] = useCreateInvitationMutation();
	console.log(data, error, isLoading);

	const handleClick = () => {
		invite({ title: "test", body: "test", userId: 1 });
	};

	return <div onClick={handleClick}>{data?.title}</div>;
};

export default Landing;

import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { RootState } from "../index";

export const baseEndPoint = process.env.REACT_APP_BACKEND_API;
export const baseQuery = fetchBaseQuery({
	baseUrl: baseEndPoint,
	prepareHeaders: (headers, { getState }) => {
		// By default, if we have a token in the store, let's use that for authenticated requests
		const token = (getState() as RootState).landing;
		if (token) {
			headers.set("authentication", `Bearer ${token}`);
		}
		return headers;
	}
});

export const api = createApi({
	reducerPath: "api",
	keepUnusedDataFor: 30,
	baseQuery: baseQuery,
	endpoints: () => ({})
});
